/**
 * Logs frontend errors to the server with built-in safeguards and filtering.
 *
 * This utility handles frontend error logging across the application, including:
 * - React component errors
 * - API/fetch errors
 * - Global window errors
 * - Resource loading errors
 *
 * Key features:
 * 1. Error Debouncing: Uses shouldLogError() to prevent duplicate logs within 5 minutes
 * 2. Error Filtering: Excludes specific known errors (e.g., certain resource loading issues)
 * 3. Flexible Authentication: Supports both authenticated (accessToken) and unauthenticated error logging
 * 4. Encrypted Communication: Uses encrypted logger key for unauthenticated requests
 * 5. Silent Failure: Never throws errors to prevent error logging loops
 *
 * @param {Object} params - The parameters object
 * @param {Object} params.errorDetails - Details about the error
 * @param {string} params.errorDetails.message - Error message
 * @param {string} params.errorDetails.stack - Error stack trace
 * @param {string} params.errorDetails.type - Type of error (e.g., "Fetch Error", "React Component Error")
 * @param {string} [params.errorDetails.userAgent] - User's browser agent
 * @param {string} [params.errorDetails.url] - URL where error occurred
 * @param {Object} [params.errorDetails.additionalInfo] - Any additional context about the error
 * @param {string} [params.accessToken] - Auth token for authenticated requests
 * @param {string} [params.auth0UserId] - User's Auth0 ID for error attribution
 *
 * @returns {Promise<Object|null>} The server response or null if logging fails/is skipped
 *
 * Usage:
 * ```js
 * try {
 *   // Some code that might error
 * } catch (error) {
 *   await logErrorToServer({
 *     errorDetails: {
 *       message: error.message,
 *       stack: error.stack,
 *       type: "Component Error"
 *     },
 *     accessToken,
 *     auth0UserId
 *   });
 * }
 * ```
 */

// src/shared/utils/logErrorToServer.js
import { shouldLogError } from "@/shared/utils/errorLogDebouncer.js";
import { encryptLoggerKey } from "@/shared/utils/errorLoggerEncryption";

export const logErrorToServer = async ({ errorDetails, accessToken, auth0UserId }) => {
	const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

	// Early return if error should not be logged (debouncing)
	if (!shouldLogError(errorDetails.message)) {
		return null;
	}

	// Helper function to make the actual API call
	const makeLogRequest = async () => {
		const apiUrl = `${apiBaseUrl}/api/v1/account/errors`;
		const userAgent = errorDetails.userAgent || (typeof navigator !== "undefined" ? navigator.userAgent : "Unknown");
		const currentUrl = errorDetails.url || (typeof window !== "undefined" ? window.location.href : "Unknown");
		const domainName = "app.rentiq.com";

		// Filter out specific known errors
		const specificErrors = [
			"Resource failed to load: https://app.rentiq.com/js/gtag.js",
			"Resource failed to load: https://app.rentiq.com/_next/static/chunks/195.12db2b6bd29fa77b.js",
		];
		if (specificErrors.some((error) => errorDetails.message.includes(error))) {
			return null;
		}

		// Get encrypted key for authentication
		const encryptedKey = await encryptLoggerKey();

		// Build headers object
		const headers = {
			"Content-Type": "application/json",
			"X-Error-Logger-Key": encryptedKey,
		};
		if (accessToken) {
			headers.Authorization = `Bearer ${accessToken}`;
		}

		const response = await fetch(apiUrl, {
			method: "POST",
			headers,
			body: JSON.stringify({
				error_message: errorDetails.message,
				error_stack: errorDetails.stack,
				error_type: errorDetails.type,
				auth0_user_id: auth0UserId || null,
				user_agent: userAgent,
				url: currentUrl,
				domain_name: domainName,
				additional_info: JSON.stringify(errorDetails.additionalInfo),
			}),
		});

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}

		return response.json();
	};

	// Retry logic with exponential backoff
	const MAX_RETRIES = 2;
	let attempt = 0;

	while (attempt <= MAX_RETRIES) {
		try {
			return await makeLogRequest();
		} catch (error) {
			attempt++;

			// If we've exhausted all retries, give up silently
			if (attempt > MAX_RETRIES) {
				//console.debug("Error logging failed after all retries:", error);
				return null;
			}

			// Wait with exponential backoff before retrying
			// 1st retry: 1 second, 2nd retry: 2 seconds
			await new Promise((resolve) => setTimeout(resolve, Math.pow(2, attempt - 1) * 1000));
		}
	}

	return null;
};
