// src/shared/services/apiClient.js

/**
 * A minimal wrapper around fetch that handles common API request patterns.
 * This centralizes repeated logic like auth headers, error handling, and JSON processing
 * while remaining flexible enough for different HTTP methods and request configurations.
 *
 * Used as the foundation for all API service functions to reduce boilerplate
 * while maintaining consistent error handling for react-query integration.
 */
export const apiClient = async (
	path,
	{ accessToken, method = "GET", data, signal, errorMessage = "Request failed", isFormData = false } = {}
) => {
	try {
		const headers = {
			Authorization: `Bearer ${accessToken}`,
			// Remove Content-Type completely when using FormData
			...(!isFormData && { "Content-Type": "application/json" }),
		};

		// Don't stringify FormData
		const body = isFormData ? data : data && JSON.stringify(data);

		const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}${path}`, {
			method,
			headers,
			body, // Just pass body directly
			signal,
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || errorMessage);
		}

		if (signal?.aborted) return null;
		return await response.json();
	} catch (error) {
		if (error.name === "AbortError") {
			return null;
		}
		throw error;
	}
};
