// src/shared/components/auth/ErrorBoundary.js
import ErrorMessage from "@/shared/components/feedback/ErrorMessage";
import { logErrorToServer } from "@/shared/utils/logErrorToServer";
import React from "react";

/**
 * React Error Boundary component that catches errors in child component trees.
 *
 * This error boundary provides three key functions:
 * 1. Catches JavaScript errors during rendering, lifecycle methods, and constructors
 * 2. Logs caught errors to the server with detailed context (component stack, URL, user agent)
 * 3. Displays a fallback UI when errors occur to prevent app crashes
 *
 * The component can handle both authenticated and unauthenticated error logging,
 * accepts auth tokens and user IDs via props, and silently handles any logging
 * failures to prevent error loops.
 */
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	async componentDidCatch(error, errorInfo) {
		// Prepare error details for logging
		const userAgent = typeof navigator !== "undefined" ? navigator.userAgent : "Unknown";
		const currentUrl = typeof window !== "undefined" ? window.location.href : "Unknown";

		const errorDetails = {
			message: error.toString(),
			stack: error.stack,
			type: "React Component Error",
			userAgent: userAgent,
			url: currentUrl,
			additionalInfo: { componentStack: errorInfo.componentStack },
		};

		// Log the error to the server
		// Get auth details from props, defaulting to null if not present
		const { accessToken = null, auth0UserId = null } = this.props;

		try {
			await logErrorToServer({
				errorDetails,
				accessToken,
				auth0UserId,
			});
		} catch (err) {
			// Handle error silently
		}
		// Removed the return null statements as they are unnecessary
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;

		if (hasError) {
			// Render the custom error message component
			return <ErrorMessage />;
		}

		return children;
	}
}

export default ErrorBoundary;
