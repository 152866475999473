// src/shared/components/layout/PageLayout.js
import { useGetUser } from "@/client/hooks/users/useGetUser";
import ErrorMessage from "@/shared/components/feedback/ErrorMessage";
import LoadingOverlay from "@/shared/components/feedback/LoadingOverlay";
import LoadingState from "@/shared/components/feedback/LoadingState";
import SuccessAlert from "@/shared/components/feedback/SuccessAlert";
import NavbarSkeleton from "@/shared/components/layout/NavbarSkeleton";
import SideDrawerSkeleton from "@/shared/components/layout/SideDrawerSkeleton";
import GoogleAnalytics from "@/shared/components/marketing/GoogleAnalytics";
import { useAccessTokenQuery } from "@/shared/hooks/useAccessTokenQuery";
import { useUser } from "@auth0/nextjs-auth0/client";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";

const NavbarWithNoSSR = dynamic(() => import("@/shared/components/layout/Navbar"), { ssr: false });
const SideDrawerWithNoSSR = dynamic(() => import("@/shared/components/layout/SideDrawer"), { ssr: false });

export const PageLayout = React.memo(({ children, nonce }) => {
	const router = useRouter();
	const { pathname } = router;

	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

	// We expose showSuccessAlert through the window object as a pragmatic solution to avoid:
	// 1. React Context which would add complexity for just a simple alert
	// 2. Installing a global state management library like Redux just for alerts
	// 3. Prop drilling the showSuccessAlert function through multiple component layers
	// This allows any component to easily trigger the alert via window.showSuccessAlert()
	useEffect(() => {
		// Success alert handler
		window.showSuccessAlert = () => {
			setShowSuccessAlert(true);
			setTimeout(() => setShowSuccessAlert(false), 3000);
		};

		// Loading overlay handlers
		window.showLoadingOverlay = () => {
			setShowLoadingOverlay(true);
		};

		window.closeLoadingOverlay = () => {
			setShowLoadingOverlay(false);
		};

		// Cleanup
		return () => {
			delete window.showSuccessAlert;
			delete window.showLoadingOverlay;
			delete window.closeLoadingOverlay;
		};
	}, []);

	// Fetch user details using the useUser hook
	const { user: auth0User, isError: isErrorAuth0User, isLoading: isLoadingAuth0User } = useUser();

	const auth0UserId = auth0User?.sub;

	// Fetch the access token using a custom hook
	const { data: accessToken, isLoading: isLoadingAccessToken } = useAccessTokenQuery();

	// Memoize the isRentIQAdmincheck as it depends on user data
	const isRentIQAdmin = useMemo(() => {
		const namespace = process.env.NEXT_PUBLIC_AUTH0_NAMESPACE;
		return auth0User && auth0User[namespace]?.includes("RentIQAdmin");
	}, [auth0User]);

	const { data: user, isLoading: isLoadingUser, isError: isErrorUser } = useGetUser({ accessToken, auth0UserId });

	// Conditionally apply the left margin only if the user is logged in
	const mainClass = useMemo(() => (user ? "flex-1 overflow-auto ml-0 md:ml-56" : "flex-1 overflow-auto"), [user]);

	const isLoadingAny = isLoadingAuth0User || isLoadingAccessToken || isLoadingUser;
	const isErrorAny = isErrorAuth0User || isErrorUser;

	if (isErrorAny) return <ErrorMessage />;

	return (
		<div className="flex flex-col min-h-screen pt-16">
			{showSuccessAlert && <SuccessAlert />}
			{showLoadingOverlay && <LoadingOverlay />}
			{pathname !== "/login" && (
				<>{isLoadingAny ? <NavbarSkeleton /> : <NavbarWithNoSSR organization={user?.organization} />}</>
			)}
			<div className="flex flex-1">
				{user && (
					<>
						{isLoadingAny ? (
							<SideDrawerSkeleton />
						) : (
							<SideDrawerWithNoSSR isRentIQAdmin={isRentIQAdmin} userProperties={user?.properties} />
						)}
					</>
				)}
				<main className={mainClass}>{isLoadingAny ? <LoadingState /> : children}</main>
			</div>
			<GoogleAnalytics nonce={nonce} />
		</div>
	);
});

PageLayout.displayName = "PageLayout";
