// src/shared/hooks/useAccessTokenQuery.js
import { useAccessToken } from "@/shared/hooks/useAccessToken";
import { logErrorToServer } from "@/shared/utils/logErrorToServer";
import { useQuery } from "@tanstack/react-query";

export const useAccessTokenQuery = () => {
	const accessToken = useAccessToken();

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: ["accessToken"],
		queryFn: async () => accessToken,
		staleTime: 30 * 60 * 1000, // 30 minutes
		cacheTime: 25 * 60 * 60 * 1000, // 25 hours
		refetchInterval: 6 * 60 * 60 * 1000, // Every 6 hours
		enabled: !!accessToken, // Only run query when accessToken is available
		onError: (error) => {
			const errorDetails = {
				message: error.message,
				stack: error.stack,
				type: "AccessTokenQueryError",
				userAgent: typeof navigator !== "undefined" ? navigator.userAgent : "Unknown",
				url: typeof window !== "undefined" ? window.location.href : "Unknown",
				additionalInfo: {
					detail: "Error in React Query while fetching access token",
				},
			};

			logErrorToServer({
				errorDetails,
				accessToken: null, // We're handling an access token error, so we don't want to use it
				auth0UserId: null, // We don't have access to auth0UserId in this context
			});
		},
	});
};
