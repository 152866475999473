// src/shared/utils/queryClient.js
import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false, // Disable refetch on window focus globally
			// You can add other default options here if needed
			// e.g., staleTime: 1000 * 60 * 5, // 5 minutes
			// cacheTime: 1000 * 60 * 60, // 1 hour
			// retry: 1, // Retry failed requests once
		},
	},
});
