// src/shared/utils/errorLoggerEncryption.js
const ERROR_LOGGING_KEY = process.env.NEXT_PUBLIC_ERROR_LOGGING_KEY;
const IV_LENGTH = 12; // Change to 12 bytes for AES-GCM standard

export const encryptLoggerKey = async () => {
	if (!ERROR_LOGGING_KEY) {
		throw new Error("Error logging key is not configured");
	}

	// Use Web Crypto API since we're in the browser
	const encoder = new TextEncoder();
	const iv = crypto.getRandomValues(new Uint8Array(IV_LENGTH));

	const key = await crypto.subtle.importKey("raw", encoder.encode(ERROR_LOGGING_KEY), "AES-GCM", false, ["encrypt"]);

	const encrypted = await crypto.subtle.encrypt(
		{
			name: "AES-GCM",
			iv,
			tagLength: 128, // Specify tag length explicitly
		},
		key,
		encoder.encode(ERROR_LOGGING_KEY)
	);

	// The last 16 bytes of the encrypted data is the auth tag in Web Crypto API
	const encryptedArray = new Uint8Array(encrypted);
	const authTag = encryptedArray.slice(-16); // Last 16 bytes
	const encryptedData = encryptedArray.slice(0, -16); // Everything except last 16 bytes

	// Convert to hex strings for transmission
	const encryptedHex = Array.from(encryptedData)
		.map((b) => b.toString(16).padStart(2, "0"))
		.join("");
	const ivHex = Array.from(iv)
		.map((b) => b.toString(16).padStart(2, "0"))
		.join("");
	const authTagHex = Array.from(authTag)
		.map((b) => b.toString(16).padStart(2, "0"))
		.join("");

	return `${ivHex}:${encryptedHex}:${authTagHex}`;
};
