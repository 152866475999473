// src/shared/components/feedback/ErrorMessage.js
import { ArrowPathIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";

const ErrorMessage = () => {
	const handleRefresh = () => {
		window.location.reload();
	};

	return (
		<div className="bg-red-50 p-4 shadow-md mx-auto max-w-2xl my-8">
			<div className="flex items-center">
				<div className="flex-shrink-0">
					<ExclamationCircleIcon className="h-5 w-5 text-red-500" />
				</div>
				<div className="ml-3">
					<h3 className="text-lg font-medium text-red-800">Oops! Something went wrong</h3>
					<div className="mt-2 text-sm text-red-700">
						<p>We apologize for the inconvenience. Please try refreshing the page.</p>
						<p className="mt-2">
							If the problem persists, please contact{" "}
							<a
								href="mailto:support@rentiq.com"
								className="font-medium underline hover:text-red-600 transition-colors"
							>
								support
							</a>
							.
						</p>
					</div>
					<div className="mt-4">
						<button
							onClick={handleRefresh}
							className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors"
						>
							<ArrowPathIcon className="h-4 w-4 mr-2" />
							Refresh Page
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ErrorMessage;
