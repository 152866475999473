// src/shared/components/feedback/LoadingOverlay.js
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const LoadingOverlay = () => {
	return (
		<div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
			<div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full mx-4 flex flex-col items-center space-y-4">
				<AiOutlineLoading3Quarters className="w-8 h-8 text-blue-600 animate-spin" />
				<div className="text-center">
					<p className="text-gray-700 font-medium">Submitting...</p>
					<p className="text-gray-500 text-sm mt-1">Please do not close this window</p>
				</div>
			</div>
		</div>
	);
};

export default LoadingOverlay;
