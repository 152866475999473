// pages/_app.js
import AuthWrapper from "@/shared/components/auth/AuthWrapper";
import useGlobalErrorHandler from "@/shared/hooks/useGlobalErrorHandler";
import "@/shared/styles/globals.css";
import { queryClient } from "@/shared/utils/queryClient";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import { QueryClientProvider } from "@tanstack/react-query";
import Head from "next/head";
import { useEffect, useState } from "react";

function App({ Component, pageProps }) {
	const [nonce, setNonce] = useState("");

	useEffect(() => {
		const metaNonce = document.querySelector("meta[name='csp-nonce']");
		if (metaNonce) {
			setNonce(metaNonce.getAttribute("content"));
		}
	}, []);

	useGlobalErrorHandler();

	return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<title>Rent IQ</title>
			</Head>
			<QueryClientProvider client={queryClient}>
				<UserProvider
					useRefreshTokens={true}
					authorizationParams={{
						scope: "openid profile email offline_access",
					}}
				>
					<AuthWrapper nonce={nonce}>
						<Component {...pageProps} />
					</AuthWrapper>
				</UserProvider>
			</QueryClientProvider>
		</>
	);
}

export default App;
