// src/client/hooks/users/useGetUser.js
import { getUser } from "@/client/services/users/getUser";
import { useApiQuery } from "@/shared/hooks/useApiQuery";

export const useGetUser = ({ auth0UserId, accessToken }) => {
	return useApiQuery({
		queryKey: ["user", auth0UserId],
		queryFn: () => getUser({ auth0UserId, accessToken }),
		accessToken,
		auth0UserId,
		enabled: !!auth0UserId && !!accessToken,
	});
};
