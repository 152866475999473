// src/shared/hooks/useApiQuery.js
import { logErrorToServer } from "@/shared/utils/logErrorToServer";
import { useQuery } from "@tanstack/react-query";

/**
 * A wrapper around react-query's useQuery that provides:
 * - Standard error logging with logErrorToServer
 * - Common default cache/stale times
 * - Automatic auth token enabling
 * - Optional abort controller handling
 *
 * Used as the foundation for all API queries (not mutations) to ensure consistent:
 * - Error handling
 * - Caching behavior
 * - Authentication checks
 */
export const useApiQuery = ({
	queryKey,
	queryFn,
	accessToken,
	auth0UserId,
	enabled = !!accessToken,
	staleTime = 30 * 60 * 1000,
	cacheTime = 60 * 60 * 1000,
	withAbortSignal = false,
	refetchInterval,
	refetchIntervalInBackground,
}) => {
	const resource = Array.isArray(queryKey) ? queryKey[0] : queryKey;

	const wrappedQueryFn = withAbortSignal
		? async ({ signal }) => {
				try {
					return await queryFn({ signal });
				} catch (error) {
					if (error.name === "AbortError") {
						return undefined;
					}
					throw error;
				}
			}
		: queryFn;

	return useQuery({
		queryKey,
		queryFn: wrappedQueryFn,
		enabled,
		staleTime,
		cacheTime,
		refetchInterval,
		refetchIntervalInBackground,
		onError: (error) => {
			logErrorToServer({
				errorDetails: {
					message: error.message || "An unknown error occurred",
					stack: error.stack || "No stack trace available",
					type: "Fetch Error",
					auth0UserId,
					additionalInfo: {
						detail: `Error while fetching ${resource}`,
					},
				},
				accessToken,
				auth0UserId,
			});
		},
	});
};
